import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Not sure what you're looking for, but it's not
      here <span role="img" aria-label="stuck out tongue emoji">😜</span></p>
  </Layout>
)

export default NotFoundPage
